 .tblnovo{
    font-weight: 700;
    background-color: rgba(144, 227, 252, 0.15) !important;
}
 .tblenviando{
    font-weight: 700;
    background-color: rgba(255, 253, 147, 0.15) !important;
}
 .tblerro{
    font-weight: 700;
    background-color: rgba(253, 118, 100, 0.15) !important;
}
 .tblaguardando{
    font-weight: 700;
    background-color: rgba(147, 233, 255, 0.925) !important;
}
.tblemprocessamento{
    font-weight: 600;
    background-color: rgba(60, 100, 107, 0.347) !important;
}
.tblrecebidoocorrencias{
    font-weight: 600;
    background-color: rgba(207, 204, 25, 0.334) !important;
}
.tblrecebido{
    font-weight: 950;
    background-color: rgba(24, 161, 19, 0.387) !important;
}
.tblcancelado{
    font-weight: 700;
    background-color: rgba(82, 82, 82, 0.15) !important;
}

.tblexcluido{
    font-weight: 700;
    background-color: rgba(82, 82, 82, 0.15) !important;
}
.tblprocessado{
    font-weight: 700;
    background-color: rgba(169, 253, 176, 0.15) !important;
}
.tblrejeitado{
    font-weight: 600;
    background-color: rgba(210, 43, 43, 0.221) !important;
}
.tblpendente{
    background-color: rgba(255, 253, 147, 0.15) !important;
}

.custom .p-scrollpanel-bar {
    background-color: rgba(60, 100, 107, 0.347) !important;
    opacity: 1;
    transition: background-color .2s;
}

.dialog-demo .p-dialog-content {
    overflow: hidden !important; 
}