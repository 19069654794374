.row-ativo {
    background-color: #e0f7fa;
}

.datatable-style-demo .row-inativo {
    background-color: #ee1127;
}

.breadcrumb-changed {
    width: 100%;
    display: inline-block; 
    margin-bottom: 25px;
}