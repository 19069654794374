body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tabview-custom span {
  margin: 0 0.5rem;
}

/*Correção botões do Fullcalendar*/
.fc-button {
  display: inline!important;
}

.footer {
  background: #f8f9fa!important;
}

.p-badge-success-outlined {
  border: 1px solid #4CAF50!important;
  padding: 4px 8px!important;
  border-radius: 4px!important;
  background: transparent!important;
  color: #4CAF50!important;
}

.p-badge-warning-outlined {
  border: 1px solid #FBC02D!important;
  padding: 4px 8px!important;
  border-radius: 4px!important;
  background: transparent!important;
  color: #FBC02D!important;
}

.p-badge-info-outlined {
  border: 1px solid #0288D1!important;
  padding: 4px 8px!important;
  border-radius: 4px!important;
  background: transparent!important;
  color: #0288D1!important;
}

.p-badge-danger-outlined {
  border: 1px solid #D32F2F!important;
  padding: 4px 8px!important;
  border-radius: 4px!important;
  background: transparent!important;
  color: #D32F2F!important;
}

.p-badge-success-no-border {
  padding: 4px 8px!important;
  background: transparent!important;
  color: #4CAF50!important;
}

.p-badge-warning-no-border {
  padding: 4px 8px!important;
  background: transparent!important;
  color: #FBC02D!important;
}

.p-badge-info-no-border {
  padding: 4px 8px!important;
  background: transparent!important;
  color: #0288D1!important;
}

.p-badge-danger-no-border {
  padding: 4px 8px!important;
  background: transparent!important;
  color: #D32F2F!important;
}

.p-text-default {
  color: #495057!important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal!important;
}

.p-badge-square {
  padding: 4px 8px!important;
  border-radius: 4px!important;
}
.p-badge {
  height: auto!important;
  line-height: 1.2rem!important;
}
.p-badge-warning {
  color: #ffffff!important;
}
.p-datatable-tbody {
  width: auto!important;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td{
  padding: 0.1rem 0.1rem!important;
}
.link-custom {
  color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-decoration: underline;
}

.card-personalizado .p-card .p-card-content .minha-classe-estilizada {
  padding: 0px !important;
}

.p-speeddial-button {
  width: 2.357em!important;
  height: 2.357rem!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}
